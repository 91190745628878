<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar 
            :menu="dataSource.Data.Menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab>
                {{ $t("Карточка") }}
            </v-tab>

        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item>
                <v-form class="wrapperForm" ref="form" lazy-validation>
                    <v-row dense>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">
                            <!--Блок Сведения о регистрации-->
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Контрагент") }}</div>
                                    
                                    <!--поле Фамилия-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Фамилия")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.FirstName ? dataSource.Data.Object.FirstName : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                v-model="dataSource.Data.Object.FirstName"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                :rules="requiredRule"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Имя-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Имя")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.LastName ? dataSource.Data.Object.LastName : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                v-model="dataSource.Data.Object.LastName"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                :rules="requiredRule"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Отчество-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Отчество")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.MiddleName ? dataSource.Data.Object.MiddleName : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                v-model="dataSource.Data.Object.MiddleName"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Дата рождения-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Дата_рождения")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.BirthDate ? $moment(dataSource.Data.Object.BirthDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                            </div>
                                            <v-menu
                                                v-else
                                                v-model="birthDateMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="birthDate | formattedDate"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on" 
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        class="datepick-input"
                                                    >
                                                    </v-text-field>
                                                </template>

                                                <v-date-picker
                                                    v-model="birthDate"
                                                    @input="birthDateMenu = false"
                                                    color="teal"
                                                    :first-day-of-week="1"
                                                >
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">
                            <!--Блок Сведения о регистрации-->
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Дополнительные_сведения") }}</div>

                                    <!--поле Срок хранения-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Пол")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Sex != null ? sexTypes.find(x => x.id === dataSource.Data.Object.Sex).Value : $t("Не_указано") }}
                                            </div>
                                            <v-select
                                                v-else
                                                :items="sexTypes"
                                                item-value="id"
                                                item-text="Value"
                                                v-model="dataSource.Data.Object.Sex"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Контакты") }}</div>
                                    
                                    <div class="onlyReadData more-per-lab-wrap chips-without-label">
                                        <v-contact-item
                                            v-for="contact in contacts.filter(x => !x.DelRec)"
                                            :key="contact.id"
                                            :type="contact.__type === 'ContactPhoneNew:#Avrora.Objects.Common' ? 'phone': 'email'"
                                            :title="contact.Value"
                                            :description="contact.Description"
                                            :hide-load="true"
                                            :is-read-only="isViewMode"
                                            @editcontact="onEditContact" 
                                            @deletecontact="onDeleteContact"
                                        />

                                        <v-chip
                                            class="person-label immutable-text"
                                            small
                                            label
                                            outlined
                                            v-if="!isViewMode"
                                            @click="onAddContact"
                                        >
                                            <i class="fas fa-plus dark-gray-color"></i>
                                            <div>{{ $t("Добавить_контакт") }}</div>
                                        </v-chip>

                                    </div>
                                        
                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-form>
            </v-tab-item>

        </v-tabs-items>

        <ContactsDlg ref="ContactsRef"
            @on-save-contact="onSaveContact"
        />

    </div>
</template>

<script>
import sys from '@/services/system';
import { mapActions, mapGetters } from 'vuex';
import Toolbar from '@/components/Toolbar.vue';
import i18n from '@/i18n';
import ContactsDlg from '@/components/dialogs/ContactsDlg'
import { httpAPI } from "@/api/httpAPI";

export default {
    name: "ContractorPerson",
    props: {
        id: { type: String, required: true }
    },
    components: {
       Toolbar,
       ContactsDlg
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    data: () => ({
        tab: null,
        dataSource: null,
        birthDateMenu: false,
        sexTypes: [
            { id: 0, Value: i18n.t("Не_определено") },
            { id: 1, Value: i18n.t("Мужской") },
            { id: 2, Value: i18n.t("Женский") },
        ]
    }),
    computed: {
        ...mapGetters('references', { docTypes: 'GetDocumentTypes' }),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            iinRule: 'getIinRule'
        }),
        isViewMode() {
            return this.dataSource?.Data.FormId === "0101031";
        },
        birthDate: {
            get: function() {
                if (this.dataSource.Data.Object.BirthDate)
                    return this.$moment(this.dataSource.Data.Object.BirthDate).format('YYYY-MM-DD');
                
                return this.dataSource.Data.Object.BirthDate;
            },
            set: function(value) {
                this.dataSource.Data.Object.BirthDate = `/Date(${Date.parse(value)})/`;
            }
        },
        contacts() {
            if (this.dataSource?.Data?.Object?.ContactList == null)
                return [];
            
            return this.dataSource.Data.Object.ContactList.data_list;
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        async getData(){
            let response = await httpAPI({
                url: this.id === 'new'
                    ? `api/new?type=Chancellery.ContractorPerson`
                    : `api/open?type=Chancellery.ContractorPerson&id=${this.dataSource ? this.dataSource.Data.Object.id : this.id}`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if (response) 
                this.dataSource = response.data.payload;
        },
        async onToolbarClick (event, button) {
            let action = this[button.Action];
            
            if (action && typeof action === 'function') {
                if (button.Action == 'Save' || button.Action == 'SaveAndClose') {
                    let validate_result = this.$refs.form.validate();

                    if (validate_result) {
                        await action(event, button);
                    }
                    else {
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                    }
                }
                else {
                    await action(event, button);
                }
            }
            else
                this.$notify.alert(`Действие ${button.Action} не реализовано.`);
        },
        async Edit() {
            let response = await httpAPI({
                url: `api/edit?type=Chancellery.ContractorPerson&id=${this.dataSource.Data.Object.id}`,
                method: 'GET'
            });

            if (response) {
                this.dataSource.Data.Menu = response.data.payload.Data.Menu;
                this.dataSource.Data.FormId = response.data.payload.Data.FormId;
            }
        },
        async SaveAndClose() {
            this.setOverlayVisible({ visible: true });

            let response = await httpAPI({
                url: `api/save?type=Chancellery.ContractorPerson`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { Content : JSON.stringify({ parent: this.dataSource.Data.Object, childrens: null }) },
            });

            if (response) {
                this.dataSource = response.data.payload;
                this.$notify.success(response.data.payload?.Message ?? response.data.message);
            }

            this.setOverlayVisible({ visible: false });
        },
        async onAddContact() {
            await this.openContactDialog(null);
        },
        async onSaveContact(contact) {
            if (!this.dataSource.Data.Object.ContactList)
                this.dataSource.Data.Object.ContactList = { data_list: []};
            let contacts = JSON.parse(JSON.stringify(this.dataSource.Data.Object.ContactList.data_list));
            
            if (contact.isNew) {
                let newContact =
                {
                    __type: contact.type === 'phone' ? "ContactPhoneNew:#Avrora.Objects.Common" : "ContactEMailNew:#Avrora.Objects.Common",
                    DelRec: false,
                    Type: contact.type === 'phone' ? 2 : 1,
                    Value: contact.value,
                    Description: contact.description,
                    id: sys.generateUUID(),
                    is_new_record: true
                };
                contacts.push(newContact);
            }
            else {
                let existedContact = contacts.find(x => x.id === contact.id);
                
                if (existedContact) {
                    existedContact.__type =  contact.type === 'phone' ? "ContactPhoneNew:#Avrora.Objects.Common" : "ContactEMailNew:#Avrora.Objects.Common",
                    existedContact.Value = contact.value;
                    existedContact.Description = contact.description;
                }
            }
            this.dataSource.Data.Object.ContactList.data_list = contacts;
        },
        async onEditContact(contactId) {
            let existedContact = this.dataSource.Data.Object.ContactList.data_list.find(x => x.id === contactId);

            if (existedContact)
                await this.openContactDialog({
                    id: existedContact.id,
                    type: existedContact.__type === 'ContactPhoneNew:#Avrora.Objects.Common' ? 'phone': 'email',
                    value: existedContact.Value,
                    description: existedContact.Description
                });
        },
        async onDeleteContact(contactId) {
            let existedContact = this.dataSource.Data.Object.ContactList.data_list.find(x => x.id === contactId);

            if (existedContact)
                existedContact.DelRec = true;
        },
        async openContactDialog(contact) {
            try {
                await this.$refs.ContactsRef.open(contact);
            } 
            catch (ex) {
                console.log(ex);
            }
        }
    },
    updated() {
        if(this.dataSource !== null){
            this.$refs?.form?.validate();
        }  
    },
    async created() {        
        (async() => {
            this.getData();
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs.form.validate();
        })();
    }
}
</script>